import { StyleResponseDTO } from '../../Models/Styles';
import { AppDispatch } from '../../Hooks/useTypedDispatch';
import { updateShowCharacterDetails } from '../../Requests/Studio/Character';
import { ShowCharacterDetailsUpdateRequest } from '../../Models/Character';

export enum CharacterActions {
  UPDATE_ALL_CHARACTERS = 'UPDATE_ALL_CHARACTERS',
  ADD_TO_ALL_CHARACTERS = 'ADD_TO_ALL_CHARACTERS',
  UPDATE_SHOW_CHARACTERS = 'UPDATE_SHOW_CHARACTERS',
  UPDATE_SHOW_CHARACTER_DETAILS = 'UPDATE_SHOW_CHARACTER_DETAILS',
  UPDATE_TRAINING_CHARACTERS = 'UPDATE_TRAINING_CHARACTERS',
  SET_CURRENT_CHARACTER = 'SET_CURRENT_CHARACTER',
  ADD_TO_TRAINING_CHARACTERS = 'ADD_TO_TRAINING_CHARACTERS',
  SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE = 'SET_CHARACTER_MODAL_LIBRARY_VIEW_STATE',
  SET_ALL_AVAILABLE_STYLES = 'SET_ALL_AVAILABLE_STYLES',
  SET_EPISODE_CHARACTERS = 'SET_EPISODE_CHARACTERS',
  ADD_TO_EPISODE_CHARACTERS = 'ADD_TO_EPISODE_CHARACTERS',
  ADD_TO_SHOW_CHARACTERS = 'ADD_TO_SHOW_CHARACTERS',
  REMOVE_FROM_SHOW_CHARACTERS = 'REMOVE_FROM_SHOW_CHARACTERS',
  REMOVE_FROM_EPISODE_CHARACTERS = 'REMOVE_FROM_EPISODE_CHARACTERS',
}

export const setAllAvailableStyles = (styles: StyleResponseDTO[]) => {
  return {
    type: CharacterActions.SET_ALL_AVAILABLE_STYLES,
    payload: styles,
  };
};

export const updateShowCharacterDetailsThunk =
  (request: ShowCharacterDetailsUpdateRequest) => async (dispatch: AppDispatch) => {
    try {
      const response = await updateShowCharacterDetails(request);
      dispatch({
        type: CharacterActions.UPDATE_SHOW_CHARACTER_DETAILS,
        payload: request.showCharacter,
      });
    } catch (e) {
      console.error('Error while fetching show characters', e);
    }
  };

export interface UpdateCharacterAction {
  type: CharacterActions;
  payload: any;
}

export type CharacterActionTypes = UpdateCharacterAction;
