import { SaveImageResponse } from '../../Models/SaveImage';

export enum SavedImagesActions {
  SET_SAVED_IMAGES = 'SET_SAVED_IMAGES',
  UPDATE_SAVED_IMAGES = 'UPDATE_SAVED_IMAGES',
  APPEND_SAVED_IMAGES = 'APPEND_SAVED_IMAGES',
  APPEND_BULK_SAVED_IMAGES = 'APPEND_BULK_SAVED_IMAGES',
  UPDATE_SAVED_IMAGE = 'UPDATE_SAVED_IMAGE',
  MARK_AS_FAILED = 'MARK_AS_FAILED',
}

export interface SetSavedImagesAction {
  type: typeof SavedImagesActions.SET_SAVED_IMAGES;
  payload: SaveImageResponse[];
}

export interface UpdateSavedImagesAction {
  type: typeof SavedImagesActions.UPDATE_SAVED_IMAGES;
  payload: SaveImageResponse[];
}

export interface AppendSavedImageAction {
  type: typeof SavedImagesActions.APPEND_SAVED_IMAGES;
  payload: SaveImageResponse;
}

export interface AppendBulkSavedImagesAction {
  type: typeof SavedImagesActions.APPEND_BULK_SAVED_IMAGES;
  payload: SaveImageResponse[];
}

export interface UpdateSavedImageAction {
  type: typeof SavedImagesActions.UPDATE_SAVED_IMAGE;
  payload: SaveImageResponse;
}

export interface MarkAsFailedAction {
  type: typeof SavedImagesActions.MARK_AS_FAILED;
  payload: string[];
}

export const updateSavedImages = (images: SaveImageResponse[]) => {
  return {
    type: SavedImagesActions.UPDATE_SAVED_IMAGES,
    payload: images,
  };
};

export const appendSavedImage = (image: SaveImageResponse): AppendSavedImageAction => {
  return {
    type: SavedImagesActions.APPEND_SAVED_IMAGES,
    payload: image,
  };
};

export const appendBulkSavedImage = (images: SaveImageResponse[]): AppendBulkSavedImagesAction => {
  return {
    type: SavedImagesActions.APPEND_BULK_SAVED_IMAGES,
    payload: images,
  };
};

export const markImageAsFailed = (generationIds: string[]): MarkAsFailedAction => {
  return {
    type: SavedImagesActions.MARK_AS_FAILED,
    payload: generationIds,
  };
};

export type SavedImagesActionTypes =
  | SetSavedImagesAction
  | UpdateSavedImagesAction
  | AppendSavedImageAction
  | AppendBulkSavedImagesAction
  | UpdateSavedImageAction
  | MarkAsFailedAction;
