import mixpanel from 'mixpanel-browser';
import { User } from 'firebase/auth';
import { config } from '../Config/Config';
import { TrackingEvents } from '../Constants/TrackingEvents';
import { TrackingProperties } from '../Constants/TrackingProperties';
import { TimedTrackingEvents } from '../Constants/TimedTrackingEvents';
import { mixpanel_server_url } from '../Constants/Tracking';
import { dashTimedEventsDictionary } from './DashAnalytics';

const appVersion = process.env.REACT_APP_VERSION ?? '';
const buildTimestamp = process.env.REACT_APP_BUILD_TIMESTAMP ?? '';

export const initMixpanelCreator = () => {
  mixpanel.init(
    config.mixpanelCreatorToken ?? '',
    {
      debug: false,
      ignore_dnt: true,
      api_host: mixpanel_server_url,
      // record_sessions_percent: 100,
      // record_block_selector: '',
      // record_mask_text_selector: '.sensitive',
    },
    'creator'
  );
};

const registerSuperProperties = (user: User, browserLang: string) => {
  // @ts-ignore
  mixpanel.creator.register_once({
    userId: user.uid,
    userName: user.displayName,
    userEmail: user.email,
    browserLang: browserLang,
  });
};

const registerUserProfile = (user: User) => {
  // @ts-ignore
  mixpanel.creator.identify(user.uid);
  // @ts-ignore
  mixpanel.creator.people.set({
    name: user.displayName,
    email: user.email,
    verified: user.emailVerified,
  });
};

export const configureMixpanelCreatorUser = (user: User, browserLang: string) => {
  registerSuperProperties(user, browserLang);
  registerUserProfile(user);
};

export const deRegisterMixpanelCreatorUser = () => {
  // @ts-ignore
  mixpanel.creator.reset();
};

export const trackMixpanelCreatorEvent = (
  event: TrackingEvents,
  properties: TrackingProperties
) => {
  if (
    event === TrackingEvents.imageLoaded ||
    event === TrackingEvents.globalImageLoad ||
    event === TrackingEvents.imageLoadTimeOut
  ) {
    return;
  }
  // @ts-ignore
  mixpanel.creator.track(event, {
    ...properties,
    appVersion: appVersion,
    buildTimestamp: buildTimestamp,
  });
};

export const timeMixpanelCreatorEvent = (event: TimedTrackingEvents, eventName?: string) => {
  if (event === TimedTrackingEvents.apiCall) {
    return;
  }
  // @ts-ignore
  mixpanel.creator.time_event(eventName ? eventName : event);
};

export const trackTimedMixpanelCreatorEvent = (
  event: TimedTrackingEvents,
  properties: TrackingProperties,
  uuid: string,
  eventName?: string
) => {
  if (event === TimedTrackingEvents.apiCall) {
    return;
  }
  properties = {
    ...properties,
    time_duration: (
      new Date().getTime() - (dashTimedEventsDictionary[uuid]?.startTime ?? new Date().getTime())
    ).toString(),
  };
  // @ts-ignore
  mixpanel.creator.track(eventName ? eventName : event, properties);
};
