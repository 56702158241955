import { GenerateImageType, ImageGenerationState } from './GenerateImage';
import { SaveImageResponse } from './SaveImage';

export type ControlNetTabMap = {
  [key in GenerateImageType]: number;
};

export interface MultiInpaintState {
  inpaintImage: string;
  width: number;
  height: number;
  image: SaveImageResponse;
}

export interface MultiInpaintSelection {
  images: string[];
  selectedIndex: number;
  processedImages: any[];
  processedOriginalImages: string[];
}

export interface MultiImageSelection {
  images: MultiInpaintSelection[];
  imageGenerationState: ImageGenerationState[];
  generatedImages: string[][];
  initImage: any;
  currentColor: string;
}

export interface CarouselModalState {
  selectedImage: SaveImageResponse;
  batchImages: SaveImageResponse[];
  isBatch: boolean;
}

export interface MultiInpaintDrawState {
  strokeStyle: string;
  isUpdate: boolean;
}

export interface TabSelectorState {
  imageGenerationTab: string;
  controlNetTabSelector: ControlNetTabMap;
  isMultiInpaintopen: MultiInpaintState | undefined;
  multiInpaintSelection: MultiImageSelection | undefined;
  carouselModalOpen: CarouselModalState | undefined;
  multiInpaintDraw: MultiInpaintDrawState | undefined;
  showInpaintModel: boolean;
  canEditInpaintModel: boolean;
  onFocus: boolean;
  imageLibraryOpen: boolean;
  saveCanvasState?: boolean;
  showDetailsModalTab?: number;
  dashboardSearch?: string;
  openCreateCollection?: boolean;
}

export const getTabSelectorInitState = (): TabSelectorState => {
  return {
    imageGenerationTab: GenerateImageType.TXT2IMG.toString(),
    controlNetTabSelector: {
      [GenerateImageType.TXT2IMG]: 0,
      [GenerateImageType.IMG2IMG]: 0,
      [GenerateImageType.INPAINT]: 0,
      [GenerateImageType.MULTI_INPAINT]: 0,
      [GenerateImageType.OUTPAINT]: 0,
      [GenerateImageType.CLOTHES]: 0,
    },
    isMultiInpaintopen: undefined,
    multiInpaintSelection: undefined,
    carouselModalOpen: undefined,
    multiInpaintDraw: undefined,
    showInpaintModel: false,
    canEditInpaintModel: false,
    onFocus: false,
    imageLibraryOpen: false,
    showDetailsModalTab: undefined,
    dashboardSearch: undefined,
    openCreateCollection: false,
  };
};
