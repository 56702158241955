import React, { SetStateAction, useState } from 'react';
import { CharacterAge } from '../../../../../Models/CharacterCreationOptions';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { t } from 'i18next';
import { ReactComponent as InfantIcon } from '../../../../../Assets/Icons/character-age-infant.svg';
import { ReactComponent as UnsetIcon } from '../../../../../Assets/Icons/unset.svg';
import { ReactComponent as ChildIcon } from '../../../../../Assets/Icons/character-age-kid.svg';
import { ReactComponent as TeenagerIcon } from '../../../../../Assets/Icons/character-age-teenager.svg';
import { ReactComponent as AdultIcon } from '../../../../../Assets/Icons/character-age-adult.svg';
import { ReactComponent as YoungAdultIcon } from '../../../../../Assets/Icons/character-age-young-adult.svg';
import { ReactComponent as OldIcon } from '../../../../../Assets/Icons/character-age-old.svg';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import { useTheme } from '@mui/material/styles';
import CharacterFeatureWithIconItem from './CharacterFeatureWithIconItem';
import { ReactComponent as CustomIcon } from '../../../../../Assets/Icons/character-feature-custom.svg';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';

export interface AgeOption {
  age: CharacterAge | null;
  label: string;
  icon: ReactJSXElement;
}
export const ageOptions: AgeOption[] = [
  {
    age: CharacterAge.INFANT,
    label: CharacterAge.INFANT,
    icon: <InfantIcon />,
  },
  {
    age: CharacterAge.CHILD,
    label: CharacterAge.CHILD,
    icon: <ChildIcon />,
  },
  {
    age: CharacterAge.TEENAGER,
    label: CharacterAge.TEENAGER,
    icon: <TeenagerIcon />,
  },
  {
    age: CharacterAge.ADULT,
    label: CharacterAge.ADULT,
    icon: <AdultIcon />,
  },
  {
    age: CharacterAge.OLD,
    label: CharacterAge.OLD,
    icon: <OldIcon />,
  },
];

interface CharacterAgeFeatureComponentProps {
  age: AgeOption;
  setAge: (value: AgeOption) => void;
  onCustomClick: () => void;
  isCustom: boolean;
}
const CharacterAgeFeatureComponent = ({
  age,
  setAge,
  onCustomClick,
  isCustom,
}: CharacterAgeFeatureComponentProps) => {
  const [customAgeValue, setAgeValue] = useState<string | null>(null);
  const theme = useTheme();
  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Character Age*')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        {ageOptions.map(option => {
          const selected = age.age === option.age;
          return (
            <CharacterFeatureWithIconItem
              key={option.label}
              label={option.label}
              icon={option.icon}
              selected={!isCustom && selected}
              onClick={() => {
                setAge(option);
                setAgeValue(null);
                if (isCustom) onCustomClick();
              }}
            />
          );
        })}
        <CharacterFeatureWithIconItem
          key={'age-custom'}
          label={t('Custom')}
          icon={<CustomIcon />}
          selected={isCustom}
          onClick={onCustomClick}
        />
      </div>
      {isCustom && (
        <CustomTextFieldV2
          key={'character-custom-age'}
          width={'100%'}
          inputProps={{ value: customAgeValue ? customAgeValue : '' }}
          onChange={e => {
            setAgeValue(e.target.value);
            setAge({ ...age, age: e.target.value as CharacterAge });
          }}
          isV2={true}
          style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
          backgroundColor={theme.palette.surface.primary}
          borderRadius={'16px'}
          ariaLabelledBy={'Character Custom Age'}
          multiline={false}
          placeholder={t('Or Enter Custom Age')}
          required={true}
        />
      )}
    </CharacterFeatureContainer>
  );
};
export default CharacterAgeFeatureComponent;
