import {
  AllChunksResponse,
  EpisodeTextToEntitiesQueueRequest,
  GetCharactersFromEpisodeChunksRequest,
  GetEpisodeToPanelChatResultRequest,
} from '../../Models/NovelPanel';
import HttpClient, { APIError } from '../../Utils/HttpClient';
import { apiPrefix, chatPrefix, storyCrafterPrefix, v2Prefix } from '../../Constants/Constants';
import {
  AddAssociationsRequest,
  AddBlocksRequest,
  AddEntitiesToBlockRequest,
  CreateEntitiesRequest,
  DeleteEntitiesRequest,
  DialogEnhanceRequest,
  DuplicateBlockRequest,
  EntityAssociationsResponse,
  EpisodeToScreenplayQueueRequest,
  MigrateCharacterInShowAndEpisodeToNewCharacterRequest,
  RegeneratePanelRequest,
  RegeneratePanelsRequest,
  StoryCrafterBlocksResponse,
  StoryCrafterEntitiesRequest,
  StoryCrafterEntitiesResponse,
  StoryCrafterEntity,
  StoryCrafterGenerationsResponse,
} from '../../Models/StoryMode/StoryCrafter';
import { ChatQueueResponse, StoryResponse } from '../../Models/Story';
import { TextEnhancerResponse } from '../../Models/TextEnhancer';

export const queueCreatePanelsFromEpisodeChunks = async (
  request: EpisodeToScreenplayQueueRequest
) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/queue/create-panels`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while creating panels');
  }
};

export const pollPanelsFromEpisodeChunksResults = async (
  request: GetEpisodeToPanelChatResultRequest
) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/queue/get-results`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels');
  }
};

export const updateEntities = async (request: StoryCrafterEntitiesRequest) => {
  try {
    const response: void = await HttpClient.put(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/entities`,
      request,
      {
        resourceId: request.showId,
      },
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels');
  }
};

export const regeneratePanel = async (request: RegeneratePanelRequest) => {
  try {
    const response: StoryCrafterGenerationsResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/regenerate-panel`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      true
    );
    return response;
  } catch (e) {
    if (e instanceof APIError) {
      throw e;
    } else {
      throw new Error('Error while generating panel');
    }
  }
};

export const regeneratePanels = async (request: RegeneratePanelsRequest) => {
  try {
    const response: StoryCrafterGenerationsResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/regenerate-panels`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels');
  }
};

export const deleteEntities = async (request: DeleteEntitiesRequest) => {
  try {
    const response: void = await HttpClient.delete(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/entities`,
      request,
      {
        resourceId: request.showId,
      },
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels');
  }
};

export const getAllBlocksForEpisode = async (episodeId: string, showId: string) => {
  try {
    const response: StoryCrafterBlocksResponse = await HttpClient.get(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/blocks/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      },
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels and comments for episode');
  }
};

export const getAllAssociationsForEpisode = async (episodeId: string, showId: string) => {
  try {
    const response: EntityAssociationsResponse = await HttpClient.get(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/associations/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      },
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels and comments for episode');
  }
};

export const addAssociations = async (request: AddAssociationsRequest) => {
  try {
    const response: EntityAssociationsResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/associations`,
      request,
      {
        resourceId: request.showId,
      }
    );
    return response;
  } catch (e) {
    throw new Error('Error while adding associations');
  }
};

export const getAllChunksForEpisode = async (episodeId: string, showId: string) => {
  try {
    const response: AllChunksResponse = await HttpClient.get(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/chunks/show/${showId}/episode/${episodeId}`,
      {
        resourceId: showId,
      },
      false
    );
    return response;
  } catch (e) {
    console.error('Error while fetching chunks for episode', e);
    throw new Error('Failed to fetch episode chunks');
  }
};

// chat based panel addition
export const queueCreateEntitiesFromText = async (request: EpisodeTextToEntitiesQueueRequest) => {
  try {
    const response = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/queue/text-to-entities`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while creating panels');
  }
};

export const getSampleStory = async (): Promise<StoryResponse> => {
  try {
    const response = await HttpClient.get(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/sample-story`,
      {},
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while fetching panels');
  }
};

// create and return new entities from details
export const createEntities = async (request: CreateEntitiesRequest) => {
  try {
    const response: StoryCrafterEntitiesResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/create-entities`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );

    return response;
  } catch (e) {
    throw new Error('Error while creating entities');
  }
};

// duplicate entire screenplay block
export const duplicateBlock = async (request: DuplicateBlockRequest) => {
  try {
    const response: StoryCrafterBlocksResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/duplicate-block`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while duplicating block');
  }
};

export const addBlocks = async (request: AddBlocksRequest) => {
  try {
    const response: StoryCrafterBlocksResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/blocks`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while adding blocks');
  }
};

export const addEntitiesToBlocks = async (request: AddEntitiesToBlockRequest) => {
  try {
    const response: StoryCrafterBlocksResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/add-entities-to-block`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );
    return response;
  } catch (e) {
    throw new Error('Error while adding entities to block');
  }
};
export const getCharactersFromEpisodeChunksV2 = async (
  request: GetCharactersFromEpisodeChunksRequest
) => {
  try {
    const response: ChatQueueResponse = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/queue/get-characters-v2`,
      request,
      {
        resourceId: request.showId,
      },
      false,
      false
    );

    return response;
  } catch (e) {
    throw new Error('Error while fetching characters');
  }
};

export const migrateCharacterInShowAndEpisodeToNewCharacter = async (
  request: MigrateCharacterInShowAndEpisodeToNewCharacterRequest,
  resourceId: string
) => {
  try {
    const response: StoryCrafterEntity[] = await HttpClient.post(
      `${apiPrefix}${v2Prefix}${storyCrafterPrefix}/panels/migrate-character`,
      request,
      {
        resourceId: resourceId,
      },
      false,
      false
    );

    return response;
  } catch (e) {
    throw new Error('Error while fetching characters');
  }
};

export const enhanceDialog = async (
  request: DialogEnhanceRequest
): Promise<TextEnhancerResponse> => {
  try {
    return await HttpClient.post(
      `${apiPrefix}${v2Prefix}${chatPrefix}/enhance-dialog`,
      request,
      {},
      false,
      true
    );
  } catch (e) {
    console.error('Error while enhancing dialog');
    throw e;
  }
};
