import { AppDispatch } from '../../Hooks/useTypedDispatch';
import { ReduxState } from '../ReduxInterface';
import {
  MigrateCharacterInShowAndEpisodeToNewCharacterRequest,
  StoryCrafterPanelCharacter,
  StoryCrafterPanelDetails,
} from '../../Models/StoryMode/StoryCrafter';
import { AppStateActions } from './AppState';
import { migrateCharacterInShowAndEpisodeToNewCharacter } from '../../Requests/Studio/StoryCrafter';
import { fetchAllVoices } from '../../Requests/Studio/Voices';
import { Voices } from '../../Models/Voices';

export enum StoryCrafterStateActionV2 {
  SET_STORY_CHUNKS = 'SET_STORY_CHUNKS',
  SET_EPISODE_CHUNKS = 'SET_EPISODE_CHUNKS',
  SET_ENTITIES = 'SET_ENTITIES',
  SET_BLOCKS = 'SET_BLOCKS',
  SET_NEW_BLOCKS = 'SET_NEW_BLOCKS',
  ADD_TO_UPDATED_BLOCKS = 'ADD_TO_UPDATED_BLOCKS',
  REMOVE_FROM_UPDATED_BLOCKS = 'REMOVE_FROM_UPDATED_BLOCKS',
  ADD_TO_UPDATED_DIALOGS = 'ADD_TO_UPDATED_DIALOGS',
  SET_ASSOCIATIONS = 'SET_ASSOCIATIONS',
  SET_SHAPE_ASSOCIATION_MAP = 'SET_SHAPE_ASSOCIATION_MAP',
  SET_CHARACTER_EXTRACTION_LOADING = 'SET_CHARACTER_EXTRACTION_LOADING',
  SET_SCREENPLAY_LOADING = 'SET_SCREENPLAY_LOADING',
  SET_CHARACTER_MODAL_OPEN = 'SET_CHARACTER_MODAL_OPEN',
  SET_ADD_CHARACTER_MODAL_FOR_VALIDATION_OPEN = 'SET_ADD_CHARACTER_MODAL_FOR_VALIDATION_OPEN',
  SET_ADD_PANELS_FROM_SCRATCH_LOADING = 'SET_ADD_PANELS_FROM_SCRATCH_LOADING',
  SET_ADD_PANELS_LOADING = 'SET_ADD_PANELS_LOADING',
  SET_DELETING_ENTITY = 'SET_DELETING_ENTITY',
  SET_EXPAND_ALL = 'SET_EXPAND_ALL',
  RESET_STORYCRAFTER_STATE = 'RESET_STORYCRAFTER_STATE',
  SET_STORY_TYPE = 'SET_STORY_TYPE',
  SET_STORYCRAFTER_STORY = 'SET_STORYCRAFTER_STORY',
  SET_STORYCRAFTER_SELECTED_OPTION = 'SET_STORYCRAFTER_SELECTED_OPTION',
  SET_STORYCRAFTER_STORY_LOADING = 'SET_STORYCRAFTER_STORY_LOADING',
  SET_STORYCRAFTER_STORY_ERROR = 'SET_STORYCRAFTER_STORY_ERROR',
  SET_STORYCRAFTER_LAST_BLOCK_SEEN = 'SET_STORYCRAFTER_LAST_BLOCK_SEEN',
  SET_STORYCRAFTER_LAST_IMAGE_GENERATED = 'SET_STORYCRAFTER_LAST_IMAGE_GENERATED',
  SET_FETCHING_CHUNKS = 'SET_FETCHING_CHUNKS',
  SET_BULK_EDITING_STATE = 'SET_BULK_EDITING_STATE',
  SET_COPIED_BLOCK = 'SET_COPIED_BLOCK',
  UPDATE_STORY_CRAFTER_ENTITIES = 'UPDATE_STORY_CRAFTER_ENTITIES',
  SET_SHOW_VOICES_LOADING = 'SET_SHOW_VOICES_LOADING',
  SET_SHOW_VOICES_ERROR = 'SET_SHOW_VOICES_ERROR',
  SET_SHOW_VOICES_SUCCESS = 'SET_SHOW_VOICES_SUCCESS',
}

export const checkForMissingCharacter =
  ({ blockIds }: { blockIds: string[] } = { blockIds: [] }) =>
  (dispatch: AppDispatch, getState: () => ReduxState) => {
    const state = getState();
    const { showCharacters } = state.character;
    const { blocks } = state.storyCrafterStateV2;

    const showCharacterIds = new Set(showCharacters.map(char => char.character_id));
    const missingCharacters = new Set<StoryCrafterPanelCharacter>();
    // if block id is not empty, check against block id, else check all blocks. if length is 0, check all blocks
    const filteredBlocks =
      blockIds.length > 0 ? blocks.filter(block => blockIds.includes(block.entityId)) : blocks;
    const hasMissingCharacters = filteredBlocks.some(block =>
      block.panels.some(panel => {
        const details = panel.details as StoryCrafterPanelDetails;
        const missing = (details.characters || []).filter(
          char => !showCharacterIds.has(char.dashCharacterId ?? '')
        );
        missing.forEach(char => missingCharacters.add(char));
        return missing.length > 0; // Stop iteration if missing characters are found
      })
    );

    if (hasMissingCharacters) {
      dispatch({
        type: AppStateActions.OPEN_CHARACTER_STORY_VALIDATION_MODAL,
        payload: true,
      });
      return true;
    }
    return false;
  };

export const migrateCharacterInShowAndEpisodeToNewCharacterThunk =
  (request: MigrateCharacterInShowAndEpisodeToNewCharacterRequest, resourceId: string) =>
  async (dispatch: AppDispatch) => {
    // make api request, on success update entities?
    try {
      // do any loading actions
      const response = await migrateCharacterInShowAndEpisodeToNewCharacter(request, resourceId);
      if (response && response.length > 0) {
        dispatch({
          type: StoryCrafterStateActionV2.UPDATE_STORY_CRAFTER_ENTITIES,
          payload: response,
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

export interface StoryCrafterStateV2ActionRequest {
  type: StoryCrafterStateActionV2;
  payload: any;
}

export const getVoicesThunk =
  () =>
  async (dispatch: AppDispatch, getState: () => ReduxState): Promise<Voices[]> => {
    try {
      if (getState().storyCrafterStateV2.voicesData.loading) {
        return [];
      }
      dispatch({
        type: StoryCrafterStateActionV2.SET_SHOW_VOICES_LOADING,
        payload: true,
      });
      const response = await fetchAllVoices();
      dispatch({
        type: StoryCrafterStateActionV2.SET_SHOW_VOICES_SUCCESS,
        payload: response,
      });
      return response;
    } catch (e) {
      dispatch({
        type: StoryCrafterStateActionV2.SET_SHOW_VOICES_ERROR,
        payload: e,
      });
      return [];
    }
  };
