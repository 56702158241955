import HttpClient from '../../Utils/HttpClient';
import { apiPrefix, v2Prefix, voicePrefix } from '../../Constants/Constants';
import { Voices } from '../../Models/Voices';

export const fetchAllVoices = async (): Promise<Voices[]> => {
  try {
    return HttpClient.get(`${apiPrefix}${v2Prefix}${voicePrefix}/all`);
  } catch (error) {
    console.error(`Unable to fetch user wallet balance`);
    throw error;
  }
};
