import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Link, Skeleton, Stack } from '@mui/material';
import { AppStateActions } from '../../../../Redux/Actions/AppState';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from '../../../../Redux/ReduxInterface';
import { Link as RouterLink } from 'react-router-dom';

import {
  getCustomerBillingPortal,
  startSubscription,
  topUpUserWallet,
} from '../../../../Requests/Studio/User';
import {
  SubscriptionPlan,
  UserSubscriptionPaymentResponse,
  UserSubscriptionPlanEnum,
  WalletTopUpRequest,
  WalletTopUpResponse,
} from '../../../../Models/User';
import { t } from 'i18next';
import { AuthContext } from '../../../../Provider/AuthProvider';
import { trackEvent } from '../../../../Utils/Analytics';
import { TrackingEvents } from '../../../../Constants/TrackingEvents';
import { Text14R, Text24SB } from '../../../../Components/CustomTypography/CustomTypography';
import CustomModal from '../../../../Components/CustomModal/CustomModal';
import CustomButton from '../../../../Components/CustomButton/CustomButton';
import PlanDescription from './Components/PlanDescription';
import CustomIcon from '../../../../Components/CustomIcon/CustomIcon';
import dayjs from 'dayjs';
import creditsUsage from './data/creditUsage';

interface SubscriptionModalProps {
  showPaymentsModal: boolean;
}

const SubscriptionModalV3: React.FC<SubscriptionModalProps> = ({ showPaymentsModal }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const quotaState = useSelector((state: ReduxState) => state.quotaState);
  const subscriptionState = useSelector((state: ReduxState) => state.subscriptionState);
  const { data, loading: plansLoading } = subscriptionState;
  const { plans: subscriptionPlans, subscribedPlans } = data;
  subscriptionPlans.sort((a, b) => Number(a.metadata.POSITION) - Number(b.metadata.POSITION));
  const userPlan = quotaState.data.currentPlan;
  const [apiLoadingPlanId, setApiLoadingPlanId] = useState<null | string>(null);
  const walletBalance = quotaState.data.walletBalance;

  const style = {
    height: '100%',
    width: '100%',
    pt: '52px',
    overflow: 'auto',
    borderRadius: '8px',
    maxWidth: '960px',
  };

  const openUrl = (url: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
  };

  const handleModalClose = () => {
    dispatch({
      type: AppStateActions.OPEN_PAYMENTS_MODAL_V3,
      payload: false,
    });
    dispatch({
      type: AppStateActions.SET_PAYMENTS_MODAL_TEXT,
      payload: '',
    });
    trackEvent(
      {
        event: TrackingEvents.paymentSubscriptionModalClosed,
        properties: {
          currentPlan: userPlan,
          walletBalance: walletBalance.toString(),
        },
      },
      'CREATOR'
    );
  };

  const handleCloseModal = () => {
    dispatch({
      type: AppStateActions.OPEN_PAYMENTS_MODAL_V3,
      payload: false,
    });
  };

  const handleInitiateTopUp = async (plan: SubscriptionPlan) => {
    try {
      setApiLoadingPlanId(plan.id);
      const paymentSuccessLink = new URL(window.location.href);
      paymentSuccessLink.searchParams.set('success', 'true');
      paymentSuccessLink.searchParams.set('planType', plan.metadata.PLAN_TITLE);
      paymentSuccessLink.searchParams.set('creditsForPlan', plan.metadata.DESCRIPTION);
      const paymentCancelLink = new URL(window.location.href);
      paymentCancelLink.searchParams.set('failure', 'true');
      paymentCancelLink.searchParams.set('planType', plan.metadata.PLAN_TITLE);

      const topupRequest: WalletTopUpRequest = {
        amount: Number(plan.metadata.CREDITS),
        quantity: 1,
        productId: plan.id,
        paymentSuccessLink: paymentSuccessLink.href,
        paymentCancelLink: paymentCancelLink.href,
      };
      const paymentSessionResponse: WalletTopUpResponse = await topUpUserWallet(topupRequest);

      trackEvent(
        {
          event: TrackingEvents.paymentTopupInitiated,
          properties: {
            currentPlan: userPlan,
            walletBalance: walletBalance.toString(),
          },
        },
        'CREATOR'
      );
      setApiLoadingPlanId(null);
      openUrl(paymentSessionResponse.checkoutSessionLink);
    } catch (err) {
      setApiLoadingPlanId(null);
      console.error(err);
    }
  };

  const handleCreateSubscription = async (plan: SubscriptionPlan) => {
    try {
      if (isEnterprise) {
        handleManageSubscription();
        return;
      }
      setApiLoadingPlanId(plan.id);
      const paymentSuccessLink = new URL(window.location.href);
      paymentSuccessLink.searchParams.set('success', 'true');
      paymentSuccessLink.searchParams.set('planType', plan.metadata.PLAN_TITLE);
      paymentSuccessLink.searchParams.set('creditsForPlan', plan.metadata.DESCRIPTION);
      const paymentCancelLink = new URL(window.location.href);
      paymentCancelLink.searchParams.set('failure', 'true');
      paymentCancelLink.searchParams.set('planType', plan.metadata.PLAN_TITLE);

      const paymentSessionResponse: UserSubscriptionPaymentResponse = await startSubscription({
        amount: plan.price,
        quantity: 1,
        productId: plan.id,
        priceId: plan.priceId,
        paymentSuccessLink: paymentSuccessLink.href,
        paymentCancelLink: paymentCancelLink.href,
        email: auth.currentUser?.email ?? '',
      });

      trackEvent(
        {
          event: TrackingEvents.paymentSubscriptionCheckoutInitiated,
          properties: {
            currentPlan: userPlan,
            walletBalance: walletBalance.toString(),
          },
        },
        'CREATOR'
      );
      setApiLoadingPlanId(null);
      openUrl(paymentSessionResponse.checkoutSessionLink);
    } catch (err) {
      console.error(err);
      setApiLoadingPlanId(null);
      trackEvent(
        {
          event: TrackingEvents.paymentSubscriptionCheckoutInitiatedFailure,
          properties: {
            error: err instanceof Error ? err.message : 'Unknown error',
          },
        },
        'CREATOR'
      );
    }
  };

  const handleManageSubscription = async () => {
    if (isAdmin) {
      return alert('You are an admin!');
    }
    const response: { url: string } = await getCustomerBillingPortal();
    openUrl(response.url);
  };

  const isAdmin =
    userPlan == UserSubscriptionPlanEnum.ADMIN || userPlan == UserSubscriptionPlanEnum.STUDIO;
  const isEnterprise = userPlan == UserSubscriptionPlanEnum.ENTERPRISE;
  useEffect(() => {
    if (showPaymentsModal) {
      trackEvent(
        {
          event: TrackingEvents.paymentSubscriptionModalOpened,
          properties: {
            currentPlan: userPlan,
            walletBalance: walletBalance.toString(),
          },
        },
        'CREATOR'
      );
    }
  }, [showPaymentsModal]);

  const userSubscribedPlan = subscribedPlans.find(plan => plan.state === 'ACTIVE');
  const userActivePlanDetails = subscriptionPlans.find(plan =>
    subscribedPlans.find(userPlan => userPlan.productId === plan.id && userPlan.state === 'ACTIVE')
  );

  return (
    <CustomModal
      onClose={handleCloseModal}
      open={showPaymentsModal}
      trackEventModalName={'paymentSubscriptionModal'}
    >
      <Stack sx={style}>
        <Stack direction={'row'} justifyContent={'space-between'} sx={{ width: '100%' }} gap={4}>
          <Stack width={'100%'} gap={'16px'}>
            <Text24SB color={theme.palette.text.primary}>
              {t('Dashtoon subscription plans')}
            </Text24SB>
            <Stack
              mt={'12px'}
              px={'8px'}
              py={'12px'}
              direction={'row'}
              borderRadius={'1.5rem'}
              width={'100%'}
              borderColor={`${theme.palette.surface.tertiary} !important`}
              border={'1px solid'}
              gap={'8px'}
              justifyContent={'flex-start'}
              alignItems={'center'}
            >
              <CustomIcon name={'info'} fill={theme.palette.object.primary} size={32} />
              <Stack direction={'column'}>
                <Text14R color={theme.palette.object.secondary} style={{ lineHeight: '20px' }}>
                  <>
                    {t(`You are currently in the `)}{' '}
                    <b>
                      {' '}
                      {isAdmin || isEnterprise
                        ? userPlan
                        : userActivePlanDetails?.name ?? t('Starter Plan')}
                      .
                    </b>{' '}
                    {isAdmin || isEnterprise
                      ? null
                      : userActivePlanDetails && userSubscribedPlan
                      ? t(`Your next billing date is `) +
                        dayjs(userSubscribedPlan.validThroughDate)
                          .subtract(2, 'day')
                          .format('DD MMM YYYY')
                      : t(`Start your comic creation journey with `) +
                        creditsUsage.initialAccountCreation +
                        t(` Free credits.`)}
                  </>
                </Text14R>
                <Text14R color={theme.palette.object.secondary} style={{ lineHeight: '20px' }}>
                  {isAdmin || isEnterprise || userActivePlanDetails ? (
                    <>
                      {t('You can check out your usage details ')}{' '}
                      <Link
                        onClick={() => {
                          handleCloseModal();
                          dispatch({
                            type: AppStateActions.SET_CREDITS_USAGE_MODAL,
                            payload: true,
                          });
                        }}
                        sx={{ textDecoration: 'none', cursor: 'pointer' }}
                      >
                        {t('here')}
                      </Link>{' '}
                    </>
                  ) : (
                    t('Earn back 150 credits for every episode you publish for a show.')
                  )}
                </Text14R>
                <RouterLink
                  to={'/plans'}
                  onClick={() => {
                    dispatch({
                      type: AppStateActions.OPEN_PAYMENTS_MODAL_V3,
                      payload: false,
                    });
                  }}
                  style={{ textDecoration: 'none' }}
                >
                  <Box>
                    <Text14R color={theme.palette.object.secondary}>
                      <Link sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                        {t('Learn More')}
                      </Link>
                    </Text14R>
                  </Box>
                </RouterLink>
              </Stack>
              <Box ml={'auto'} justifySelf={'flex-end'}>
                {userActivePlanDetails && userSubscribedPlan && (
                  <Box
                    flexShrink={1}
                    width={{
                      xs: '100%',
                      sm: 'auto',
                    }}
                    maxWidth={{
                      xs: '100%',
                      sm: '320px',
                    }}
                  >
                    <CustomButton
                      width={'100%'}
                      foregroundColor={theme.palette.object.primary}
                      backgroundColor={theme.palette.surface.tertiary}
                      hasBorder={false}
                      text={t('Manage Subscription')}
                      onClick={handleManageSubscription}
                      variant={'contained'}
                      padding={'8px 16px'}
                      isLoading={plansLoading}
                      disabled={plansLoading}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Stack
          direction={'row'}
          sx={{ height: '496px', marginTop: '16px', width: '100%' }}
          gap={2}
          // keep all boxes same height
          alignItems={'stretch'}
          mb={5}
        >
          {plansLoading && (
            <Skeleton
              variant={'rectangular'}
              sx={{ borderRadius: '8px' }}
              height={'496px'}
              width={'100%'}
            />
          )}
          {subscriptionPlans
            .filter(item => item.metadata.VISIBILITY !== 'hidden')
            .map(item => (
              <Box height={'100%'} flex={1} key={item.id}>
                <PlanDescription
                  planImage={item.image}
                  title={t(item.metadata.PLAN_TITLE)}
                  price={item.metadata.ACTUAL_PRICE}
                  strikedPrice={item.metadata.ORIGINAL_PRICE}
                  dashed={item.metadata.TYPE !== 'RECURRING'}
                  planFrequency={
                    item.metadata.TYPE === 'RECURRING' ? t('per month') : t('one time')
                  }
                  ctaText={
                    item.metadata.TYPE === 'RECURRING'
                      ? isEnterprise
                        ? t('Downgrade Plan')
                        : userActivePlanDetails
                        ? userActivePlanDetails.metadata.PLAN_TITLE === item.metadata.PLAN_TITLE
                          ? t('Current Plan')
                          : userActivePlanDetails.metadata.POSITION < item.metadata.POSITION
                          ? t('Upgrade Plan')
                          : t('Downgrade Plan')
                        : t('Upgrade Plan')
                      : t('Buy Plan')
                  }
                  totalCredits={item.metadata.DESCRIPTION}
                  subtitle={item.metadata.SUBTITLE}
                  onClick={() => {
                    if (userActivePlanDetails && item.metadata.TYPE === 'RECURRING') {
                      handleManageSubscription();
                    } else if (item.metadata.TYPE === 'ONE_TIME') {
                      handleInitiateTopUp(item);
                    } else {
                      handleCreateSubscription(item);
                    }
                  }}
                  minWidth={'216px'}
                  recommended={item.metadata.RECOMMENDED === 'true'}
                  loading={apiLoadingPlanId === item.id}
                  disabled={!!apiLoadingPlanId && apiLoadingPlanId !== item.id}
                />
              </Box>
            ))}
        </Stack>

        <Stack direction={'row'} justifyContent={'space-between'} fontSize={'14px'}>
          <Stack>
            <Box>
              {t('Need more credits? ')}
              <Link
                sx={{
                  textDecoration: 'none',
                }}
                href="mailto:help@dashtoon.com?subject=Need More credits"
              >
                {t('Contact Us →')}
              </Link>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </CustomModal>
  );
};

export default SubscriptionModalV3;
