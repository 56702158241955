import { apiPrefix, clothesLibraryPrefix, v2Prefix } from '../../Constants/Constants';
import httpClient from '../../Utils/HttpClient';
import {
  ClothesLibraryDeleteRequest,
  ClothesLibraryInsertRequest,
  ClothesLibraryResponse,
  ClothesLibraryUpdateRequest,
} from '../../Models/Clothes';

export const getAllClothes = async (showId: string): Promise<ClothesLibraryResponse[] | null> => {
  try {
    return httpClient.get(`${apiPrefix}${v2Prefix}${clothesLibraryPrefix}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to fetch clothes library for ${showId}`);
    return null;
  }
};

export const insertClothes = async (
  data: ClothesLibraryInsertRequest
): Promise<ClothesLibraryResponse | null> => {
  try {
    return httpClient.post(`${apiPrefix}${v2Prefix}${clothesLibraryPrefix}/add`, data, {
      resourceId: data.showId,
    });
  } catch (error) {
    console.error(`Unable to insert clothes`);
    return null;
  }
};

export const updateClothes = async (
  data: ClothesLibraryUpdateRequest,
  showId: string
): Promise<ClothesLibraryResponse | null> => {
  try {
    return httpClient.post(`${apiPrefix}${v2Prefix}${clothesLibraryPrefix}/update`, data, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Unable to update clothes`);
    return null;
  }
};

export const deleteClothes = async (
  data: ClothesLibraryDeleteRequest,
  showId: string
): Promise<ClothesLibraryResponse | null> => {
  try {
    return httpClient.delete(
      `${apiPrefix}${v2Prefix}${clothesLibraryPrefix}/remove`,
      {
        id: data.id,
      },
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`Unable to delete clothes`);
    return null;
  }
};
