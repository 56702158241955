import { ShortcutsState } from '../Redux/Reducers/Shortcuts';
import { TextualEmbeddingDTO } from './TextualEmbedding';
import {
  ColorContext,
  ControlNetState,
  EditType,
  GenerateImageType,
  getInitialControlNetState,
  getInitialT2IState,
  InpaintFillType,
  InpaintType,
  T2IAdapterState,
} from './GenerateImage';
import { ControlNet, ControlNetType, T2IAdapter } from './ControlNet';
import { defaultWeight } from '../Constants/StyleConstants';
import { string } from 'prop-types';
import { SaveImageResponse } from './SaveImage';
import { Expression } from './Expressions';
import { DEFAULT_CHARACTER_WEIGHT } from '../Constants/ImageGeneration';
import { GenModeEnum } from './GenMode';
import { PanelDetailCharacter } from './NovelPanel';

export declare interface Crop {
  x: number;
  y: number;
  width: number;
  height: number;
  unit: 'px' | '%';
}

export interface ImageGenCharacterDetails {
  id: string;
  name: string;
  metadata: string;
}

export type AssetUrlData = {
  assetId: string;
  url: string;
  imageId?: string;
  visibility: boolean;
};

export interface ImageGenerationState {
  prompt?: string;
  negativePrompt?: string;
  selectedImageUrl: string;
  width?: number;
  height?: number;
  batchSize?: number;
  characterId?: string;
  type: GenerateImageType;
  isGenerating: boolean;
  prevPrompt: string;
  editType: EditType;
  seed: number;
  steps: number;
  cfg: number;
  style: string;
  characterName: string;
  autoFixFace: boolean;
  controlNet: Array<ControlNetState>;
  controlNetPreprocessorImages: string[];
  initImage?: string;
  initImageCropBounds?: Crop;
  maskImage?: string;
  greenMaskImage?: string;
  combinedMaskImage?: string;
  multiInpaintState?: ImageGenerationState[];
  selectedCharacterPrompt: string;
  strength?: number;
  liveStrength?: number;
  activeControlNetTab: number;
  inpaintType?: InpaintType;
  inpaintFillType?: InpaintFillType;
  imageSegment?: string;
  colorContext?: ColorContext[];
  characterWeight: number;
  styleWeight: number;
  allowStyleWeight?: boolean;
  defaultStyleWeight?: number;
  textualEmbeddings: TextualEmbeddingDTO[];
  enableDiffusersInpaint?: boolean;
  color?: string;
  activeGroup: string;
  isImage2ImageLayerEnabled: boolean;
  img2imgAssetIds: string[];
  inpaintAssetIds: string[];
  controlNetAssetIds: string[][];
  isInpaintLayerEnabled: boolean;
  imageLayerTabSelected: number;
  inpaintLines: LineInterface[];
  paintLines: LineInterface[];
  inpaintImage?: SaveImageResponse;
  paintImage?: SaveImageResponse;
  combinedPaintImage?: SaveImageResponse;
  maskImageObject?: SaveImageResponse;
  eraserImageObject?: SaveImageResponse;
  isImageHistoryVisible?: boolean;
  isPreviewLayerActive?: boolean;
  pinnedImage?: SaveImageResponse;
  selectedExpression?: string;
  selectedExpressionStrength?: number;
  enhancedPromptUsed?: boolean;
  seenLatestImages?: boolean;
  generationStatus?: 'none' | 'success' | 'error' | 'loading';
  selectedModeTab: 'gen' | 'ref' | 'guide';
  controlNetUrlArray: AssetUrlData[][];
  assetUrlArray: AssetUrlData[];
  combinedImg2ImgImage?: SaveImageResponse;
  deleting?: boolean;
  t2iAdapters: T2IAdapter[];
  t2iAdaptersSelected: boolean;
  t2iAdapterState: T2IAdapterState[];
  selectedT2IAdapters: T2IAdapter[];
  enabledT2IAdapters: boolean[];
  t2iAdapterWeight: number[];
  turboMode?: boolean;
  photoMakerMode?: boolean;
  charRefImage?: string[];
  tabValue?: number;
  enableControlNet?: boolean;
  isMaskVisible?: boolean;
  totalMaskLayers?: number;
  inprocessMaskLayers?: string[];
  currentMaskIndex?: number;
  generationEstimateForMultiInpaintBatch?: number;
  startMultiInpaintTime?: number;
  isMultiInpaintLowPriority?: boolean;
  disableFoocusInpaint?: boolean;
  characters: ImageGenCharacterDetails[];
  autoInpaintPrompt: string;
  allowAutoInpaint?: boolean;
  autoInpaintControlNet?: ControlNetState;
  isGeneratingAutoInpaint?: boolean;
  inpaintControlNetState?: ControlNetState;
  generationMethod?: GenModeEnum;
  imageIdsToPreview?: number[];
  characterDetails: PanelDetailCharacter[];
  enhancedGeneration?: boolean;
  previewImageIndex?: number;
}

export enum CanvasMode {
  STORY = 'STORY',
  SCREENPLAY = 'SCREENPLAY',
  DASHTOON = 'DASHTOON',
  PUBLISH = 'PUBLISH',
}

export interface LineInterface {
  id: string;
  points: number[];
  color: string;
  size: number;
  opacity: number;
  globalCompositeOperation: GlobalCompositeOperation | undefined;
}

export const getInitialImageGenerationState = (
  type: GenerateImageType,
  steps?: number,
  characterWeight?: number,
  promptVal?: string,
  characterIdVal?: string,
  characterNameVal?: string,
  stopProp?: boolean,
  tabValue?: number,
  controlNets?: ControlNet[],
  enableControlNet?: boolean
): ImageGenerationState => {
  return {
    type: type,
    batchSize: 1,
    selectedImageUrl: '',
    cfg: 7,
    characterId: characterIdVal ?? '',
    characterName: characterNameVal ?? '',
    activeControlNetTab: 0,
    // adding the below code to dynamically add controlNets, since it depends on style
    controlNet: controlNets
      ? [...controlNets.map(item => getInitialControlNetState(item.name))]
      : // legacy code
        [
          getInitialControlNetState(ControlNetType.SCRIBBLE_ADVANCED.toString()),
          getInitialControlNetState(ControlNetType.OPENPOSE.toString()),
          getInitialControlNetState(ControlNetType.EXPRESSION.toString()),
        ],
    controlNetPreprocessorImages: ['', '', ''],
    height: 512,
    negativePrompt: '',
    prompt: promptVal ?? '',
    prevPrompt: '',
    editType: EditType.INPAINT_EDIT,
    seed: -1,
    steps: steps ?? 20,
    style: '',
    width: 512,
    isGenerating: false,
    autoFixFace: false,
    selectedCharacterPrompt: '',
    strength: 0.5,
    liveStrength: 0.8,
    inpaintType: InpaintType.ONLY_MASKED,
    inpaintFillType: InpaintFillType.ORIGINAL,
    characterWeight: characterWeight ?? DEFAULT_CHARACTER_WEIGHT,
    styleWeight: defaultWeight,
    textualEmbeddings: [],
    initImage: undefined,
    initImageCropBounds: undefined,
    maskImage: undefined,
    greenMaskImage: undefined,
    combinedMaskImage: undefined,
    imageSegment: undefined,
    colorContext: undefined,
    enableDiffusersInpaint: false,
    activeGroup: '',
    multiInpaintState: undefined,
    color: undefined,
    isImage2ImageLayerEnabled: false,
    isInpaintLayerEnabled: false,
    imageLayerTabSelected: 0,
    img2imgAssetIds: [],
    inpaintAssetIds: [],
    controlNetAssetIds: [[], [], []],
    inpaintLines: [],
    paintLines: [],
    isImageHistoryVisible: false,
    isPreviewLayerActive: false,
    selectedExpression: '',
    selectedModeTab: 'gen',
    controlNetUrlArray: [],
    assetUrlArray: [],
    t2iAdapters: [],
    t2iAdaptersSelected: false,
    selectedT2IAdapters: [],
    t2iAdapterState: [getInitialT2IState(), getInitialT2IState(), getInitialT2IState()],
    enabledT2IAdapters: [false, false, false],
    t2iAdapterWeight: [1, 1, 1],
    turboMode: true,
    photoMakerMode: false,
    charRefImage: [],
    tabValue: tabValue ?? 0,
    enableControlNet: enableControlNet ?? false,
    isMaskVisible: true,
    totalMaskLayers: 1,
    characters: [],
    autoInpaintPrompt: '',
    allowAutoInpaint: false,
    generationMethod: GenModeEnum.T2I,
    imageIdsToPreview: [],
    characterDetails: [],
    previewImageIndex: 0,
  };
};

export interface GenerationMenuState {
  id: string;
  generationState: ImageGenerationState;
}

export interface GenerationMenuStateList {
  canvasMode?: CanvasMode;
  generationMenus: GenerationMenuState[];
}

export const GenerationMenuInitialState: GenerationMenuStateList = {
  generationMenus: [],
  canvasMode: CanvasMode.DASHTOON,
};

export interface InpaintImage {
  image: string;
  width: number;
  height: number;
}

export enum BaseModels {
  REALISTIC_COMIC = 'REALISTIC_COMIC',
  SDXL_COMIC = 'SDXL_COMIC',
  STORY_AI_COMIC = 'STORY_AI_COMIC',
}
