import { SavedImagesActions, SavedImagesActionTypes } from '../Actions/SavedImages';
import {
  SavedImageMetadataTypes,
  SavedImageState,
  SaveImageResponse,
} from '../../Models/SaveImage';

export interface SavedImagesState {
  savedImages: SaveImageResponse[];
}

const initialState: SavedImagesState = {
  savedImages: [],
};

export const SavedImagesReducer = (
  state: SavedImagesState = initialState,
  action: SavedImagesActionTypes
): SavedImagesState => {
  switch (action.type) {
    case SavedImagesActions.APPEND_SAVED_IMAGES:
      // update if existing otherwise prepend
      if (state.savedImages.find(image => image.id === action.payload.id)) {
        return {
          ...state,
          savedImages: state.savedImages.map(image =>
            image.id === action.payload.id ? action.payload : image
          ),
        };
      } else {
        return { ...state, savedImages: [action.payload, ...state.savedImages] };
      }
    case SavedImagesActions.APPEND_BULK_SAVED_IMAGES:
      // update if found, if not found prepend
      const missingImages = action.payload.filter(
        image => !state.savedImages.find(savedImage => savedImage.id === image.id)
      );
      return {
        ...state,
        savedImages: [
          ...missingImages,
          ...state.savedImages.map(
            image => action.payload.find(savedImage => savedImage.id === image.id) || image
          ),
        ],
      };
    case SavedImagesActions.UPDATE_SAVED_IMAGES:
      return { ...state, savedImages: action.payload };
    case SavedImagesActions.SET_SAVED_IMAGES:
      return {
        ...state,
        savedImages: action.payload,
      };
    case SavedImagesActions.UPDATE_SAVED_IMAGE:
      return {
        ...state,
        savedImages: state.savedImages.map(image =>
          image.id === action.payload.id ? action.payload : image
        ),
      };
    case SavedImagesActions.MARK_AS_FAILED: {
      const generationIds = action.payload;
      return {
        ...state,
        savedImages: state.savedImages.map(image =>
          generationIds.includes(
            image.metadata.find(m => m.name === SavedImageMetadataTypes.USER_GEN_ID)?.value ?? ''
          )
            ? { ...image, state: SavedImageState.FAILED }
            : image
        ),
      };
    }
    default:
      return state;
  }
};
