import React, { SetStateAction, useState } from 'react';
import { CharacterHairColor } from '../../../../../Models/CharacterCreationOptions';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { t } from 'i18next';
import { ReactComponent as UnsetIcon } from '../../../../../Assets/Icons/unset.svg';
import { ReactComponent as BlackIcon } from '../../../../../Assets/Icons/hair-color-black.svg';
import { ReactComponent as BrownIcon } from '../../../../../Assets/Icons/hair-color-brown.svg';
import { ReactComponent as GoldenIcon } from '../../../../../Assets/Icons/hair-color-golden.svg';
import { ReactComponent as GreyIcon } from '../../../../../Assets/Icons/hair-color-grey.svg';
import { ReactComponent as WhiteIcon } from '../../../../../Assets/Icons/hair-color-white.svg';
import { ReactComponent as RedIcon } from '../../../../../Assets/Icons/hair-color-red.svg';
import { ReactComponent as BlueIcon } from '../../../../../Assets/Icons/hair-color-blue.svg';
import { ReactComponent as GreenIcon } from '../../../../../Assets/Icons/hair-color-green.svg';
import { ReactComponent as CustomIcon } from '../../../../../Assets/Icons/character-feature-custom.svg';
import { useTheme } from '@mui/material/styles';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import CharacterFeatureWithIconItem from './CharacterFeatureWithIconItem';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';

export interface HairColorOption {
  hairColor: CharacterHairColor | string;
  icon: ReactJSXElement;
}
export const hairColorOptions: HairColorOption[] = [
  {
    hairColor: '',
    icon: <UnsetIcon />,
  },
  {
    hairColor: CharacterHairColor.BLACK,
    icon: <BlackIcon />,
  },
  {
    hairColor: CharacterHairColor.BROWN,
    icon: <BrownIcon />,
  },
  {
    hairColor: CharacterHairColor.GOLDEN,
    icon: <GoldenIcon />,
  },
  {
    hairColor: CharacterHairColor.GREY,
    icon: <GreyIcon />,
  },
  {
    hairColor: CharacterHairColor.WHITE,
    icon: <WhiteIcon />,
  },
  {
    hairColor: CharacterHairColor.RED,
    icon: <RedIcon />,
  },
  {
    hairColor: CharacterHairColor.BLUE,
    icon: <BlueIcon />,
  },
  {
    hairColor: CharacterHairColor.GREEN,
    icon: <GreenIcon />,
  },
];
interface CharacterHairColorFeatureComponentProps {
  hairColor: HairColorOption;
  setHairColor: (value: HairColorOption) => void;
  onCustomClick: () => void;
  isCustom: boolean;
}
const CharacterHairColorFeatureComponent = ({
  hairColor,
  setHairColor,
  onCustomClick,
  isCustom,
}: CharacterHairColorFeatureComponentProps) => {
  const theme = useTheme();
  const [customHairColorValue, setCustomHairColorValue] = useState<string | null>(null);
  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Hair Color')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        {hairColorOptions.map(option => {
          const selected = hairColor.hairColor === option.hairColor;
          return (
            <CharacterFeatureWithIconItem
              key={option.hairColor || 'hair-color-unset'}
              label={option.hairColor || t('Unset')}
              icon={option.icon}
              selected={!isCustom && selected}
              onClick={() => {
                setHairColor(option);
                setCustomHairColorValue(null);
                if (isCustom) onCustomClick();
              }}
            />
          );
        })}
        <CharacterFeatureWithIconItem
          key={'hair-color-custom'}
          label={t('Custom')}
          icon={<CustomIcon />}
          selected={isCustom}
          onClick={onCustomClick}
        />
      </div>
      {isCustom && (
        <CustomTextFieldV2
          key={'character-custom-hair-color'}
          width={'100%'}
          inputProps={{ value: customHairColorValue ? customHairColorValue : '' }}
          onChange={e => {
            setCustomHairColorValue(e.target.value);
            setHairColor({ ...hairColor, hairColor: e.target.value });
          }}
          isV2={true}
          style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
          backgroundColor={theme.palette.surface.primary}
          borderRadius={'16px'}
          ariaLabelledBy={'Character Custom Hair Color'}
          multiline={false}
          placeholder={t('Or Enter Custom Hair Color')}
        />
      )}
    </CharacterFeatureContainer>
  );
};
export default CharacterHairColorFeatureComponent;
