import React from 'react';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';
import { hexToRgba } from '../../../../../Utils/imageProcess';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { useTheme } from '@mui/material/styles';

interface CharacterFeatureWithIconItemProps {
  label: string;
  icon: ReactJSXElement;
  selected: boolean;
  onClick: () => void;
}
const CharacterFeatureWithIconItem = ({
  label,
  icon,
  selected,
  onClick,
}: CharacterFeatureWithIconItemProps) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '8px',
        width: '64px',
        cursor: 'pointer',
        justifyContent: 'start',
      }}
      onClick={onClick}
    >
      <Box
        sx={{
          borderRadius: '20px',
          border: `1px solid ${
            selected ? theme.palette.primary.main : theme.palette.surface.tertiary
          }`,
          width: '64px',
          height: '64px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            borderColor: selected
              ? theme.palette.primary.main
              : hexToRgba(theme.palette.object.tertiary, 0.5),
          },
        }}
      >
        {icon}
      </Box>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Text12R color={selected ? theme.palette.primary.main : theme.palette.constant.grey}>
          {label}
        </Text12R>
      </Box>
    </div>
  );
};
export default CharacterFeatureWithIconItem;
