import {
  Character,
  CharacterStatus,
  CharacterTrainingStatus,
  DashCharacterTypes,
  ProcessType,
} from '../Models/Character';
import { CharacterCreationState } from '../Models/CharacterCreation';
import { ageOptions } from '../Screens/Studio/CharacterCreation/Components/CharacterFeaturesTab/CharacterAgeFeatureComponent';
import {
  CharacterBodyType,
  CharacterCreationType,
  CharacterEthnicity,
  CharacterHairStyle,
} from '../Models/CharacterCreationOptions';
import { hairColorOptions } from '../Screens/Studio/CharacterCreation/Components/CharacterFeaturesTab/CharacterHairColorFeatureComponent';
import { hairLengthOptions } from '../Screens/Studio/CharacterCreation/Components/CharacterFeaturesTab/CharacterHairLengthFeatureComponent';
import { eyeColorOptions } from '../Screens/Studio/CharacterCreation/Components/CharacterFeaturesTab/CharacterEyeColorFeatureComponent';
import { Theme } from '@mui/material';
import { trackEvent } from './Analytics';
import { TrackingEvents } from '../Constants/TrackingEvents';

export const getCharacterTrainingStatusFromState = (
  character: Character
): CharacterTrainingStatus => {
  switch (character.state) {
    case CharacterStatus.CREATED:
    case CharacterStatus.DATASET_GENERATION_ENQUEUED:
    case CharacterStatus.COMFY_GENERATING:
      return CharacterTrainingStatus.PRE_TRAINING;
    case CharacterStatus.COMFY_GENERATED:
      return CharacterTrainingStatus.DATASET_READY;
    case CharacterStatus.TRAINING_COMPLETED:
    case CharacterStatus.IN_TRAINING:
    case CharacterStatus.TRAINING_ENQUEUED:
    case CharacterStatus.IN_TRAINING:
      return CharacterTrainingStatus.TRAINING;
    case CharacterStatus.FAILED:
      return CharacterTrainingStatus.TRAINING_FAILED;
    case CharacterStatus.PUBLISHED:
      return CharacterTrainingStatus.PUBLISHED;
    default:
      return CharacterTrainingStatus.PUBLISHED;
  }
};

export const isCharacterTraining = (status: CharacterTrainingStatus) => {
  return (
    status === CharacterTrainingStatus.PRE_TRAINING || status === CharacterTrainingStatus.TRAINING
  );
};

export const isCharacterReadyForReview = (status: CharacterTrainingStatus) => {
  return status === CharacterTrainingStatus.DATASET_READY;
};
export const getDerivedDescription = (characterCreationState: CharacterCreationState) => {
  const {
    age,
    otherFeatures,
    dress,
    bodyType,
    eyeColor,
    hairColor,
    hairLength,
    hairStyle,
    ethnicity,
    selectedGender,
  } = characterCreationState;
  return (
    (ethnicity ? ethnicity + ', ' : '') +
    selectedGender +
    ', ' +
    (age.age ? age.age + ', ' : '') +
    (hairLength.hairLength ? hairLength.hairLength + ' ' : '') +
    (hairColor.hairColor ? hairColor.hairColor + ' ' : '') +
    (hairStyle ? hairStyle + ' ' : '') +
    (hairStyle || hairLength.hairLength || hairColor.hairColor ? 'hair, ' : '') +
    (eyeColor.eyeColor ? eyeColor.eyeColor + ' eyes, ' : '') +
    (bodyType ? bodyType + ', ' : '') +
    (dress ? dress + ', ' : '') +
    (otherFeatures ? otherFeatures : '')
  );
};

export const randomiseCharacterFeatures = (): Partial<CharacterCreationState> => {
  const randomAge = ageOptions[1 + Math.floor(Math.random() * (ageOptions.length - 1))];
  const randomEthnicity =
    Object.values(CharacterEthnicity)[
      Math.floor(Math.random() * Object.values(CharacterEthnicity).length)
    ];
  const randomHairColor =
    hairColorOptions[1 + Math.floor(Math.random() * (hairColorOptions.length - 1))];
  const randomHairStyle =
    Object.values(CharacterHairStyle)[
      Math.floor(Math.random() * Object.values(CharacterHairStyle).length)
    ];
  const randomHairLength =
    hairLengthOptions[1 + Math.floor(Math.random() * (hairLengthOptions.length - 1))];
  const randomEyeColor =
    eyeColorOptions[1 + Math.floor(Math.random() * (eyeColorOptions.length - 1))];

  const randomBodyType =
    Object.values(CharacterBodyType)[
      Math.floor(Math.random() * Object.values(CharacterBodyType).length)
    ];
  return {
    age: randomAge,
    hairColor: randomHairColor,
    hairLength: randomHairLength,
    eyeColor: randomEyeColor,
    ethnicity: randomEthnicity,
    hairStyle: randomHairStyle,
    bodyType: randomBodyType,
  };
};

export const getColorFromStatus = (status: CharacterTrainingStatus, theme: Theme): string => {
  switch (status) {
    case CharacterTrainingStatus.PRE_TRAINING:
    case CharacterTrainingStatus.TRAINING:
    case CharacterTrainingStatus.DATASET_READY:
      return theme.palette.constant.yellow;
    case CharacterTrainingStatus.PUBLISHED:
      return theme.palette.constant.blue;
    case CharacterTrainingStatus.TRAINING_FAILED:
      return theme.palette.constant.red;
  }
};

// simplified character creation type for event proprties
export const getCharacterCreationType = (
  processType: ProcessType,
  characterType: DashCharacterTypes
) => {
  if (characterType === DashCharacterTypes.PROMPT) {
    return 'PROMPT';
  }
  if (processType === ProcessType.SEMI_MANUAL_V2) {
    return 'AUTOMATED_WITH_REVIEW';
  }
  if (processType === ProcessType.AUTOMATED_V2) {
    return 'AUTOMATED';
  }
  return 'MANUAL';
};

export const trackFeatureSetEvent = (
  feature: string,
  value: string,
  option: CharacterCreationType
) => {
  trackEvent(
    {
      event: TrackingEvents.characterFeatureChanged,
      properties: {
        option: option,
        feature: feature,
        value: value,
      },
    },
    'CREATOR'
  );
};
