import React, { useEffect, useState } from 'react';
import {
  CharacterBodyType,
  CharacterHairStyle,
} from '../../../../../Models/CharacterCreationOptions';
import { useTheme } from '@mui/material/styles';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { t } from 'i18next';
import CharacterFeatureItem from './CharacterFeatureItem';
import CustomTextFieldV2 from '../../../../../Components/CustomTextFieldV2/CustomTextFieldV2';

interface CharacterBodyTypeFeatureComponentProps {
  bodyType: CharacterBodyType | string;
  setBodyType: (value: CharacterBodyType | string) => void;
}
const CharacterBodyTypeFeatureComponent = ({
  bodyType,
  setBodyType,
}: CharacterBodyTypeFeatureComponentProps) => {
  const theme = useTheme();
  const [customBodyTypeValue, setCustomBodyTypeValue] = useState<string | null>(null);

  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Body Type')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        <CharacterFeatureItem
          label={t('Unset')}
          selected={bodyType === ''}
          onClick={() => {
            setBodyType('');
            setCustomBodyTypeValue(null);
          }}
        />
        {Object.values(CharacterBodyType).map(item => {
          const selected = item === bodyType;
          return (
            <CharacterFeatureItem
              key={item}
              label={item}
              selected={selected}
              onClick={() => {
                setBodyType(item);
                setCustomBodyTypeValue(null);
              }}
            />
          );
        })}
      </div>
      <CustomTextFieldV2
        key={'character-custom-body-type'}
        width={'100%'}
        inputProps={{ value: customBodyTypeValue ? customBodyTypeValue : '' }}
        onChange={e => {
          setBodyType(e.target.value);
          setCustomBodyTypeValue(e.target.value);
        }}
        isV2={true}
        style={{ fontSize: '14px', lineHeight: '20px', padding: '8px 12px' }}
        backgroundColor={theme.palette.surface.primary}
        borderRadius={'16px'}
        ariaLabelledBy={'Character Custom Body Type'}
        multiline={false}
        placeholder={t('Or Enter Custom Body Type')}
      />
    </CharacterFeatureContainer>
  );
};
export default CharacterBodyTypeFeatureComponent;
