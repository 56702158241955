export enum AppStateActions {
  OPEN_SHOW_DETAILS_MODAL = 'OPEN_SHOW_DETAILS_MODAL',
  OPEN_PAYMENTS_MODAL = 'OPEN_PAYMENTS_MODAL',
  OPEN_PAYMENTS_MODAL_V3 = 'OPEN_PAYMENTS_MODAL_V3',
  UPDATE_SHOW_DATA = 'UPDATE_SHOW_DATA',
  OPEN_INIT_SHOW_DETAILS_MODAL = 'OPEN_INIT_SHOW_DETAILS_MODAL',
  OPEN_INPAINT_MODAL = 'OPEN_INPAINT_MODAL',
  OPEN_FTUE_MODAL = 'OPEN_FTUE_MODAL',
  OPEN_LOGIN_MODAL = 'OPEN_LOGIN_MODAL',
  SET_STORY_MODE = 'SET_STORY_MODE',
  SET_PAYMENTS_MODAL_TEXT = 'SET_PAYMENTS_MODAL_TEXT',
  OPEN_STYLE_SELECTION_MODAL = 'OPEN_STYLE_SELECTION_MODAL',
  OPEN_MANAGE_CHARACTERS_MODAL = 'OPEN_MANAGE_CHARACTERS_MODAL',
  OPEN_DASHTOON_SETTINGS_MODAL = 'OPEN_DASHTOON_SETTINGS_MODAL',
  OPEN_MANAGE_TEAM_MODAL = 'OPEN_MANAGE_TEAM_MODAL',
  OPEN_CHARACTER_STORY_VALIDATION_MODAL = 'OPEN_CHARACTER_STORY_VALIDATION_MODAL',
  SET_IS_PUBLISH_MODAL_OPEN = 'SET_IS_PUBLISH_MODAL_OPEN',
  SET_AVAILABLE_GENRES = 'SET_AVAILABLE_GENRES',
  SET_AVAILABLE_TROPES = 'SET_AVAILABLE_TROPES',
  SET_CAN_USER_EDIT = 'SET_CAN_USER_EDIT',
  SET_EPISODE_DATA = 'SET_EPISODE_DATA',
  SET_USER = 'SET_USER',
  SET_CREDITS_USAGE_MODAL = 'SET_CREDITS_USAGE_MODAL',
  SET_PUBLISHED_MODAL = 'SET_PUBLISHED_MODAL',
  SET_PUBLISH_NUDGE_CLOSED = 'SET_PUBLISH_NUDGE_CLOSED',
  SET_NEW_EPISODE_NUDGE_CLOSED = 'SET_NEW_EPISODE_NUDGE_CLOSED',
  SET_SHOW_EDITOR_NUDGE = 'SET_SHOW_EDITOR_NUDGE',
  SET_EDITOR_NUDGE_CLOSED = 'SET_EDITOR_NUDGE_CLOSED',
  SET_AUTO_THUMBNAIL_APPLIED = 'SET_AUTO_THUMBNAIL_APPLIED',
  SET_EPISODE_PROJECT_STATUS = 'SET_EPISODE_PROJECT_STATUS',
}

export interface UpdateAppStateAction {
  type: AppStateActions;
  payload: any;
}

export type AppStateActionTypes = UpdateAppStateAction;
