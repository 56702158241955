import HttpClient from '../../Utils/HttpClient';
import {
  episodeApiPrefix,
  readerApiPrefix,
  showApiPrefix,
  studioApiPrefix,
} from '../../Constants/Constants';
import {
  Episode,
  EpisodeDTO,
  EpisodeUpdateDTO,
  EpisodeUpdateResponseDTO,
  EpisodeWithThumbnailDTO,
  StudioEpisodeMetadataDTO,
  StudioEpisodeProjectStatus,
} from '../../Models/Episode';
import { Show } from '../../Models/Show';
import useFetch from '../../Hooks/useFetch';
import httpClient from '../../Utils/HttpClient';

export const fetchCreateEpisodeData = async (episodeDTO: EpisodeDTO): Promise<Episode> => {
  try {
    return HttpClient.post(`${studioApiPrefix}${episodeApiPrefix}`, episodeDTO, {
      resourceId: episodeDTO.showId,
    });
  } catch (error) {
    console.error(`fetchCreateEpisodeData failed with error: ${error}`);
    throw error;
  }
};

export const fetchAllEpisodes = async (showId: string, email: string): Promise<Show[]> => {
  try {
    return HttpClient.get(
      `${studioApiPrefix}${showApiPrefix}/myShows?offset=0&count=50&includeEpisodes=true`
    );
  } catch (error) {
    console.error(`fetchAllEpisodes failed with error: ${error}`);
    throw error;
  }
};

export const getEpisodesList = async (showId : string) =>{
  try{
    const path = `/api/v2${readerApiPrefix}${episodeApiPrefix}/show/${showId}`;
    return await HttpClient.get(path);

  } catch (error) {
    console.error(`Unable to get show by id: ${showId}`, error);
    throw error;
  }
}

export const updateEpisode = async (episode: EpisodeDTO, episodeId: string): Promise<Episode> => {
  try {
    return HttpClient.put(`${studioApiPrefix}${episodeApiPrefix}/id/${episodeId}`, episode, {
      resourceId: episode.showId,
    });
  } catch (error) {
    console.error(`updateEpisode failed with error: ${error}`);
    throw error;
  }
};

export const fetchViewsCountForEpisode = async (episodeIds: string[]) => {
  try {
    return httpClient.post(`${readerApiPrefix}/episode/views`, episodeIds);
  } catch (error) {
    console.error(`Unable to fetch views count for episodes`);
    return null;
  }
};
export const sendEpisodeUpdateData = async (
  episodeData: EpisodeWithThumbnailDTO,
  episodeId: string,
  showId: string
): Promise<EpisodeUpdateResponseDTO> => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${episodeApiPrefix}/id/${episodeId}/update`,
      episodeData,
      {
        resourceId: showId,
      }
    );
  } catch (error) {
    console.error(`update Episode with thummbnail and state failed with error: ${error}`);
    throw error;
  }
};

export const updateEpisodeThumbnail = async (
  showId: string,
  episodeUpdateRequest: EpisodeUpdateDTO,
  silent = false
) => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${episodeApiPrefix}`,
      episodeUpdateRequest,
      {
        resourceId: showId,
      },
      !silent
    );
  } catch (error) {
    console.error(`Failed to update episode thumbnail: ${error}`);
    throw error;
  }
};

export const getEpisodeData = async (episodeId: string, showId: string) => {
  try {
    return HttpClient.get(`${studioApiPrefix}${episodeApiPrefix}/${episodeId}`, {
      resourceId: showId,
    });
  } catch (error) {
    console.error(`Failed to get episode data: ${error}`);
    throw error;
  }
};

export const updateEpisodeProjectStatus = async ({
  episodeId,
  showId,
  status,
}: {
  episodeId: string;
  showId: string;
  status: string;
}): Promise<StudioEpisodeMetadataDTO> => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${episodeApiPrefix}/${episodeId}/project-status`,
      {
        status: status,
      },
      {
        resourceId: showId,
      }
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};

interface EpisodeSequenceUpdateResponse {
  episodes: Array<Episode>;
}

export const updateEpisodeSequence = async ({
  episodeId,
  showId,
  newSequence,
}: {
  episodeId: string;
  showId: string;
  newSequence: number;
}): Promise<EpisodeSequenceUpdateResponse> => {
  try {
    return HttpClient.put(
      `${studioApiPrefix}${episodeApiPrefix}/sequence`,
      {
        episodeId: episodeId,
        sequence: newSequence,
        showId: showId,
      },
      {
        resourceId: showId,
      },
      false
    );
  } catch (err) {
    console.error(err);
    throw err;
  }
};
