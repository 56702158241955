import { IconsMap } from '../CanvasV3/Icons/Icons';
import { t } from 'i18next';
import IconButton from '@mui/material/IconButton';
import CustomIcon from '../CustomIcon/CustomIcon';
import { Tooltip } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { color } from '../../Constants/Colors';

interface CustomIconButtonProps {
  icon: keyof IconsMap;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  iconSize?: number;
  fill?: string;
  stroke?: string;
  disabled?: boolean;
  tooltip?: string;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  width?: number | string;
  height?: number | string;
  iconStyle?: React.CSSProperties;
  isActive?: boolean;
  stopPropagation?: boolean;
  rotation?: number;
  ariaRoleDescription?: string;
  ariaLabel?: string;
  id?: string;
  tooltipComponentProps?: any;
  style?: React.CSSProperties;
  showNew?: boolean;
  activeHoverStyle?: string;
}

const CustomIconButton: React.FC<CustomIconButtonProps> = ({
  icon,
  onClick,
  iconSize = 24,
  fill = '#000',
  stroke = '#000',
  disabled = false,
  tooltip,
  tooltipPlacement = 'right',
  width: w = 48,
  height: h = 36,
  iconStyle,
  isActive,
  stopPropagation,
  rotation,
  ariaLabel,
  ariaRoleDescription = 'button',
  id,
  tooltipComponentProps,
  style,
  showNew,
  activeHoverStyle,
}) => {
  const theme = useTheme();
  return (
    <Tooltip title={tooltip} placement={tooltipPlacement} componentsProps={tooltipComponentProps}>
      <button
        id={id}
        onContextMenu={event => {
          event.stopPropagation();
          event.preventDefault();
          if (disabled) return;
          onClick(event);
        }}
        onClick={event => {
          if (stopPropagation) {
            event.stopPropagation();
            event.preventDefault();
          }
          if (disabled) return;
          onClick(event);
        }}
        style={{
          width: w,
          height: h,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: disabled ? 'not-allowed' : 'pointer',
          border: 'none',
          padding: 0,
          outline: 'none',
          backgroundColor: 'transparent',
          ...style,
        }}
        role={ariaRoleDescription}
        aria-label={ariaLabel}
        aria-roledescription={ariaRoleDescription}
        aria-description={tooltip}
        aria-disabled={disabled}
        aria-pressed={isActive}
      >
        <IconButton
          className="custom-button-v2"
          disabled={disabled}
          style={{
            ...iconStyle,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          sx={{
            '&:hover': {
              backgroundColor: isActive
                ? activeHoverStyle ?? theme.palette.primary.main
                : activeHoverStyle ?? `${theme.palette.tab.selectedBackground} !important`,
            },
          }}
          aria-roledescription={ariaRoleDescription}
          role={ariaRoleDescription}
          aria-label={ariaLabel}
        >
          <CustomIcon
            rotation={rotation}
            name={icon}
            fill={
              disabled && fill !== 'none'
                ? theme.palette.text.secondary
                : isActive && fill !== 'none'
                ? 'white'
                : fill
            }
            size={iconSize}
            stroke={
              disabled && stroke !== 'none'
                ? theme.palette.text.secondary
                : isActive && stroke !== 'none'
                ? 'white'
                : stroke
            }
          />
          {showNew && (
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: '3px',
                width: '22px',
                height: '10px',
                borderRadius: '2px',
                backgroundColor: color.green,
                fontSize: '8px',
                fontWeight: '600',
                color: 'white',
              }}
            >
              New
            </div>
          )}
        </IconButton>
      </button>
    </Tooltip>
  );
};

export default CustomIconButton;
