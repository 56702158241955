import React from 'react';
import { CharacterEthnicity } from '../../../../../Models/CharacterCreationOptions';
import { useTheme } from '@mui/material/styles';
import { CharacterFeatureContainer } from './CharacterFeaturesTabComponent';
import { Text12R } from '../../../../../Components/CustomTypography/CustomTypography';
import { t } from 'i18next';
import CharacterFeatureWithIconItem from './CharacterFeatureWithIconItem';
import { ageOptions } from './CharacterAgeFeatureComponent';
import CharacterFeatureItem from './CharacterFeatureItem';

interface CharacterEthnicityFeatureComponentProps {
  ethnicity: CharacterEthnicity | null;
  setEthnicity: (value: CharacterEthnicity | null) => void;
}
const CharacterEthnicityFeatureComponent = ({
  ethnicity,
  setEthnicity,
}: CharacterEthnicityFeatureComponentProps) => {
  const theme = useTheme();
  return (
    <CharacterFeatureContainer>
      <Text12R color={theme.palette.constant.grey}>{t('Ethnicity*')}</Text12R>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          flexWrap: 'wrap',
          padding: '0px 1px',
        }}
      >
        {Object.values(CharacterEthnicity).map(e => {
          const selected = e === ethnicity;
          return (
            <CharacterFeatureItem
              key={e}
              label={e}
              selected={selected}
              onClick={() => setEthnicity(e)}
            />
          );
        })}
      </div>
    </CharacterFeatureContainer>
  );
};
export default CharacterEthnicityFeatureComponent;
